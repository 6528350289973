<template>
  <div>
    <div class="container">
      <el-container>
        <transition name="el-zoom-in-center">
          <i
            v-show="!showTree"
            class="el-icon-s-unfold"
            @click="showTree=true"
            style="float:left;cursor: pointer;color:#333;font-size: 24px;margin-right: 16px;"
          ></i>
        </transition>
        <transition name="el-zoom-in-top">
          <el-aside width="240px" v-show="showTree">
            <i
              class="el-icon-s-fold"
              @click="showTree=false"
              style="float:right;cursor: pointer;color:#333;font-size: 30px;margin-right: 8px;"
            ></i>
            <type-tree typeKind="ClientType" @currentTypeArray="currentTypeArray"></type-tree>
          </el-aside>
        </transition>

        <el-main>
          <el-form :inline="true" :model="filter" size="mini">
            <el-form-item label="客户">
              <el-input v-model="filter.client" placeholder="名称、编号、拼音" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input v-model="filter.linkMan" placeholder="联系人" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-model="filter.linkTel" placeholder="联系电话" clearable></el-input>
            </el-form-item>
            <el-form-item label="显示停用">
              <el-switch v-model="filter.showAll"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-plus" @click="addClient" type="primary">添加</el-button>
            </el-form-item>
          </el-form>
          <el-table
            v-loading="loading"
            :data="data"
            stripe
            border
            :row-class-name="tableRowClassName"
            @row-dblclick="editClient"
          >
            <el-table-column label="客户编号" prop="clientCode" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column label="客户名称" prop="clientName" show-overflow-tooltip></el-table-column>
            <el-table-column label="往来余额" prop="balance" width="80" show-overflow-tooltip>
              <template slot-scope="scope">{{scope.row.balance|thousands(2)}}</template>
            </el-table-column>
            <el-table-column label="联系人" prop="linkMan" show-overflow-tooltip></el-table-column>
            <el-table-column label="联系电话" prop="linkTel" show-overflow-tooltip></el-table-column>
            <el-table-column label="客户类别" prop="typeName" show-overflow-tooltip></el-table-column>
            <el-table-column label="地址" prop="addres" show-overflow-tooltip></el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="65">
              <template slot-scope="scope">
                <el-tag type="danger" effect="dark" @click="removeItem(scope.row)">删除</el-tag>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 50, 100, 500]"
            @size-change="getData(1)"
            @current-change="getData()"
            :current-page.sync="pageIndex"
            :page-size.sync="pageSize"
            :total="pageTotal"
          ></el-pagination>
        </el-main>
      </el-container>
    </div>

    <div>
      <add-edit :item="item" @success="getData()"></add-edit>
    </div>
  </div>
</template>

<script>
import TypeTree from "@/components/BaseType/TypeTree";
import AddEdit from "@/views/client/Item";
import setName from "@/common/setName";
export default {
  components: {
    AddEdit,
    TypeTree
  },
  data() {
    return {
      showTree: this.$store.state.baseinfo.ClientType.length > 0,
      filter: {
        typeArray: []
      },
      loading: false,
      data: [],
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      item: {}
    };
  },
  watch: {
    filter: {
      handler: function(v) {
        this.getData(1);
      },
      deep: true
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.isStop) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getData(pageIndex) {
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      this.loading = true;
      let params = {
        ...this.filter,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$get("Client/GetAllClient", params)
        .then(r => {
          let data = r;
          data.list.forEach(item => {
            setName("ClientType", item, "typeId", "typeName");
          });
          this.data = data.list;
          this.pageTotal = data.pageTotal;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addClient() {
      if (this.filter.typeArray.length == 0) {
        this.item = {};
      } else {
        this.item = {
          typeId: this.filter.typeArray[0]
        };
      }
    },
    editClient(row) {
      this.item = {
        id: row.id
      };
    },
    removeItem(row) {
      this.$confirm("将删除【" + row.clientName + "】, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("Client/DeleteClient?id=" + row.id).then(r => {
            this.$message.success("操作成功！");
            this.$store.commit("removeBaseInfo", {
              info: "Client",
              id: row.id
            });
            this.getData();
          });
        })
        .catch(() => {});
    },
    currentTypeArray(array) {
      this.filter.typeArray = array;
    }
  }
};
</script>

<style>
</style>